<template>
  <div class="webgl-view">
    <div class="circle1"></div>
    <div class="circle2"></div>
    <div class="loadEffect" v-if="loadingStatus">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
    <div id="threeContained" class="threeContained"></div>
  </div>
  
</template>

<script>
import * as THREE from 'three';
import TWEEN from "@tweenjs/tween.js"
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
export default {
  name: "webgl",
  data() {
    return {
      loadingStatus:true,
      type:'dt_bottle',
      env:'test'
    };
  },
  mounted() {
    this.type = this.$route.query.type ? this.$route.query.type : 'DT_bottle'
    this.env = this.$route.query.env ? this.$route.query.env : 'test'
    this.clock = new THREE.Clock();
    this.init();
    this.animate();
    window.onpointerdown = this.selectObject;
  },
  methods: {
    init() {
      var that = this;
      let container = document.getElementById("threeContained");
      
      // 创建场景
      that.scene = new THREE.Scene();
      //背景色
      //that.scene.background = new THREE.Color(0xdddddd);
      // 创建相机 
      that.camera = new THREE.PerspectiveCamera(
        45,
        window.innerWidth / window.innerHeight,
        0.1,
        2000
      );
      // 定位相机，并且指向场景中心
      if(this.type == 'rapid_antigen_test'){
        that.camera.position.x = 80;
        that.camera.position.y = 180;
        that.camera.position.z = 80;
      }else if(this.type.indexOf('pokemonstatic') > -1){
        that.camera.position.x = 10;
        that.camera.position.y = 70;
        that.camera.position.z = 190;
      }else if(this.type.indexOf('violin') > -1){
        that.camera.position.x = -180;
        that.camera.position.y = 270;
        that.camera.position.z = -10;
      }else{
        that.camera.position.x = 30;
        that.camera.position.y = 30;
        that.camera.position.z = 170;
      }
			that.camera.lookAt(that.scene.position)
      
      // //显示三维坐标系
			// var axes = new THREE.AxesHelper(100)
			// //添加坐标系到场景中
			// that.scene.add(axes);

      // 创建渲染器
      that.renderer = new THREE.WebGLRenderer({
        antialias: true, // 是否执行抗锯齿
        alpha: true //透明
      });
      that.renderer.outputEncoding = THREE.sRGBEncoding;
      that.renderer.setPixelRatio(window.devicePixelRatio); // 设置设备像素比率。通常用于HiDPI设备，以防止输出画布模糊
      // 设置渲染器的初始颜色
      //透明
      that.renderer.setClearColor(0x000000, 0 );
      
      // 设置输出canvas画面的大小
      that.renderer.setSize(window.innerWidth, window.innerHeight);
      container.appendChild(that.renderer.domElement);


      const ambientLight = new THREE.AmbientLight(0x444444, 1);
      that.scene.add(ambientLight);
      

      if(this.type.indexOf('dt_bottle') > -1){
        const directionalLight2 = new THREE.DirectionalLight(0xB0B09D, 0.5);
        directionalLight2.position.set(-1, 0.7, -1);
        that.scene.add(directionalLight2);

        const light2 = new THREE.PointLight( 0xffffff, 0.2, 10000 );
        light2.position.set(0.32, 0.39, 0.7);
        that.scene.add( light2 );
      }else if(this.type.indexOf('cake') > -1){
        const directionalLight2 = new THREE.DirectionalLight(0xffffff, 0.5);
        directionalLight2.position.set(0, 1, 0);
        that.scene.add(directionalLight2);

        const directionalLight3 = new THREE.DirectionalLight(0xffffff, 1);
        directionalLight3.position.set(-50, -10, -300);
        that.scene.add(directionalLight3);

        const directionalLight4 = new THREE.DirectionalLight(0xffffff, 1);
        directionalLight4.position.set(100, -100, 500);
        that.scene.add(directionalLight4);
        
      }else if(this.type.indexOf('piano') > -1){
        const directionalLight2 = new THREE.DirectionalLight(0xffffff, 1);
        directionalLight2.position.set(0, 1, 0);
        that.scene.add(directionalLight2);

        const directionalLight3 = new THREE.DirectionalLight(0xffffff, 1);
        directionalLight3.position.set(-50, -10, -50);
        that.scene.add(directionalLight3);

        const directionalLight4 = new THREE.DirectionalLight(0xffffff, 1);
        directionalLight4.position.set(50, 10, 50);
        that.scene.add(directionalLight4);

      }else if(this.type.indexOf('78k_uv') > -1){
        const directionalLight2 = new THREE.DirectionalLight(0xB0B09D, 10);
        directionalLight2.position.set(-1, 0.7, -1);
        that.scene.add(directionalLight2);
        const directionalLight4 = new THREE.DirectionalLight(0xB0B09D, 10);
        directionalLight4.position.set(1, -0.7, 1);
        that.scene.add(directionalLight4);
      }else if (this.type.indexOf('phonograph') > -1){
        const directionalLight2 = new THREE.DirectionalLight(0xB0B09D, 5);
        directionalLight2.position.set(-1, 0.7, -1);
        that.scene.add(directionalLight2);
        const directionalLight3 = new THREE.DirectionalLight(0xB0B09D, 5);
        directionalLight3.position.set(1, 0.7, 1);
        that.scene.add(directionalLight3);
        const directionalLight4 = new THREE.DirectionalLight(0xB0B09D, 5);
        directionalLight4.position.set(1, -0.7, 1);
        that.scene.add(directionalLight4);
      }else if (this.type.indexOf('huangguan') > -1){
        const directionalLight2 = new THREE.DirectionalLight(0xB0B09D, 3);
        directionalLight2.position.set(-1, 0.7, -1);
        that.scene.add(directionalLight2);
        const directionalLight3 = new THREE.DirectionalLight(0xB0B09D, 3);
        directionalLight3.position.set(1, 0.7, 1);
        that.scene.add(directionalLight3);
        const directionalLight4 = new THREE.DirectionalLight(0xB0B09D, 3);
        directionalLight4.position.set(1, -0.7, 1);
        that.scene.add(directionalLight4);
      }else{
        const directionalLight2 = new THREE.DirectionalLight(0xB0B09D, 1);
        directionalLight2.position.set(-1, 0.7, -1);
        that.scene.add(directionalLight2);
        const directionalLight3 = new THREE.DirectionalLight(0xB0B09D, 1);
        directionalLight3.position.set(1, 0.7, 1);
        that.scene.add(directionalLight3);
        const directionalLight4 = new THREE.DirectionalLight(0xB0B09D, 1);
        directionalLight4.position.set(1, -0.7, 1);
        that.scene.add(directionalLight4);
      }

      



      // 材质
      //const normal = new THREE.TextureLoader().load( '3d/dt.png' );

      // model
      that.loader = new GLTFLoader();
      //let url = "3d/model/" + this.type + ".glb"
      let host = 'https://toyspace-domestic-prod.oss-cn-beijing.aliyuncs.com/'
      if(this.env == 'test'){
        host = 'https://toyspace-test.oss-cn-beijing.aliyuncs.com/'
      } else if(this.env == 'preonline'){
        host = 'https://toyspace-pre.oss-cn-beijing.aliyuncs.com/'
      }
      let url = host + this.type
      console.log(url)
			that.loader.load(url, function (object) {
        that.loadingStatus = false
        // console.log(object)
        // object.traverse( function ( child ) {
        //     console.log(child);
				// 		if ( child.isMesh ) {
				// 			child.material.specular.setScalar( 0.3 );
				// 			// child.material.normalMap = normal;
				// 		}

				// 	} );
        //console.log(object);
        object.scene.scale.set(15,15,15)
        if(that.type.indexOf('pokemonstatic') > -1){
          object.scene.position.set(0,-10,0)
        }
        if(that.type.indexOf('violin') > -1){
          object.scene.scale.set(35,35,35)
        }
        if(that.type.indexOf('gudaijianzhu_low') > -1){
          object.scene.scale.set(50,50,50)
        }
        if(that.type.indexOf('baijitun_low') > -1){
          object.scene.scale.set(8,8,8)
        }
        if(that.type.indexOf('che_low') > -1){
          object.scene.scale.set(7,7,7)
        }
        if(that.type.indexOf('konglong_low') > -1){
          object.scene.scale.set(6,6,6)
        }
        that.scene.add(object.scene);
        that.animate();
      });


      that.controls = new OrbitControls(that.camera, that.renderer.domElement);
      that.controls.enablePan = false;
      that.controls.autoRotate = false;
      that.controls.target.set(0, 1, 0);
      that.controls.update();
	//   window.addEventListener("onpointerdown", that.selectObject());
      window.addEventListener("resize", that.onWindowResize);
      that.controls.addEventListener("change", (res)=>{
        if(that.controls.getDistance() > 600){
          that.controls.maxDistance = 800
        }else{
          that.controls.minDistance = 50
        }
      });
    },

    onWindowResize() {
      this.camera.aspect = window.innerWidth / window.innerHeight;
      this.camera.updateProjectionMatrix();

      this.renderer.setSize(window.innerWidth, window.innerHeight);
    },

    animate() {
      // TWEEN.update();
      this.renderer.render( this.scene, this.camera );
      this.controls.update();
      // const delta = this.clock.getDelta();
      // if ( this.mixer ) this.mixer.update( delta );
      
      requestAnimationFrame( this.animate );
      
      
    },
    selectObject(event) {

      if (event.button != 0) return;
      console.log(event)
      const mouse = new THREE.Vector2();
      mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
      mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

      const raycaster = new THREE.Raycaster();
      raycaster.setFromCamera(mouse, this.camera);
      
      const intersected = raycaster.intersectObjects(
        this.scene.children,
        false
      );
	    console.log(intersected)
      if (intersected.length) {
        const found = intersected[0];
        const faceIndex = found.faceIndex;
        const geometry = found.object.geometry;
	    	console.log(this.load)
        // const id = ifcLoader.ifcManager.getExpressId(geometry, faceIndex);

        const modelID = found.object.modelID;
        // ifcLoader.ifcManager.createSubset( { modelID, ids: [ id ], scene, removePrevious: true, material: highlightMaterial } );
        // const props = ifcLoader.ifcManager.getItemProperties(modelID, id, true);
        // console.log(props);
        // this.renderer.render( this.scene, this.camera );
      }
    },
  },
};
</script>
<style lang="scss">
.threeContained{
  position: relative;
  z-index: 99;
}
.webgl-view {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  // background: red;
  background: linear-gradient(180deg, #13121E 0%, #222135 100%);
  backdrop-filter: blur(50px);
  background-size: cover;
  .circle1{
    position: absolute;
    top: 45%;
    transform: translateY(-50%);
    left: 45%;
    width: 85px;
    height: 85px;
    border-radius: 100%;
    background: #FFA940;
    box-shadow: 0px 4px 4px #FFFFFF;
    filter: blur(50px);
  }
  .circle2{
    position: absolute;
    top: 55%;
    transform: translateY(-50%);
    left: 29%;
    width: 85px;
    height: 85px;
    border-radius: 100%;
    background: #597EF7;
    opacity: 0.8;
    box-shadow: 0px 4px 4px #FFFFFF;
    filter: blur(50px);
  }
}
.loadEffect{
  width: 100px;
  height: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.loadEffect span{
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #333;
  position: absolute;
  animation: load 1.04s ease infinite;
}
@-webkit-keyframes load{
  0%{
      opacity: 1;
  }
  100%{
      opacity: 0.2;
  }
}
.loadEffect span:nth-child(1){
  left: 0;
  top: 50%;
  margin-top:-8px;
  animation-delay:0.13s;
}
.loadEffect span:nth-child(2){
  left: 14px;
  top: 14px;
  animation-delay:0.26s;
}
.loadEffect span:nth-child(3){
  left: 50%;
  top: 0;
  margin-left: -8px;
  animation-delay:0.39s;
}
.loadEffect span:nth-child(4){
  top: 14px;
  right:14px;
  animation-delay:0.52s;
}
.loadEffect span:nth-child(5){
  right: 0;
  top: 50%;
  margin-top:-8px;
  animation-delay:0.65s;
}
.loadEffect span:nth-child(6){
  right: 14px;
  bottom:14px;
  animation-delay:0.78s;
}
.loadEffect span:nth-child(7){
  bottom: 0;
  left: 50%;
  margin-left: -8px;
  animation-delay:0.91s;
}
.loadEffect span:nth-child(8){
  bottom: 14px;
  left: 14px;
  animation-delay:1.04s;
}
</style>
